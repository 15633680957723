<template>
  <div class="vanguardTask">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="任务名称" v-model="searchParam.assignmentName" />
        <v-select
          clearable
          :options="jinlinStatus"
          v-model="searchParam.status"
          @change="$refs.list.search()"
          label="任务状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { jinlinListUrl, deleteJinlinUrl, activityAudit } from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import { jinlinStatus, jinlinStatusMap } from "./map.js";

export default {
  name: "vanguardTask",
  data() {
    return {
      jinlinStatus,
      searchParam: {
        assignmentName: null,
        status: null,
        tenantId: this.$store.state.app.userInfo.tenantId,
      },
      tableUrl: jinlinListUrl,
      headers: [
        {
          prop: "assignmentName",
          label: "任务名称",
        },
        {
          prop: "assignmentAddress",
          label: "任务地点",
        },
        {
          prop: "picture",
          label: "任务图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "confinePersons",
          label: "限制人数",
        },
        {
          prop: "applyPersons",
          label: "认领人数",
          align: "center",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text: row.applyPersons,
              cb: () => this.toApplicationsList(row),
            }),
        },
        {
          prop: "estimatePrice",
          label: "预估金额（元）",
        },
        {
          prop: "status",
          label: "任务状态",
          formatter: (row, prop) => {
            return jinlinStatusMap[row.status];
          },
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    toApplicationsList(data) {
      this.$router.push({
        name: "jinlinOnlineApplicants",
        query: {
          id: data.id,
          name: data.assignmentName,
        },
      });
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios
          .post(`${deleteJinlinUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toAdd() {
      this.$router.push({
        name: "jinlinOnlineForm",
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "jinlinOnlineForm",
        query: { id: data.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.vanguardTask {
  box-sizing: border-box;
  height: 100%;
}
</style>
